import React, { useState } from 'react';
import { X } from 'lucide-react';
import slide1 from "./images/Slide Design/1.jpg"
import slide2 from "./images/Slide Design/2.jpg"
import slide3 from "./images/Slide Design/3.jpg"
import slide4 from "./images/Slide Design/4.jpg"
import slide5 from "./images/Slide Design/5.jpg"
import slide6 from "./images/Slide Design/6.jpg"
import slide7 from "./images/Slide Design/7.jpg"
import slide8 from "./images/Slide Design/8.jpg"
import slide9 from "./images/Slide Design/9.jpg"
import slide10 from "./images/Slide Design/10.jpg"
import slide11 from "./images/Slide Design/11.jpg"
import slide12 from "./images/Slide Design/12.jpg"
import slide13 from "./images/Slide Design/13.jpg"

import client1Logo from "./images/Clients/21.jpg";
import client2Logo from "./images/Clients/23.png";
import client3Logo from "./images/Clients/24.jpg";
import client4Logo from "./images/Clients/25.jpg";
import client5Logo from "./images/Clients/26.jpg";
import client6Logo from "./images/Clients/28.jpg";
import client7Logo from "./images/Clients/29.jpg";
import client8Logo from "./images/Clients/30.jpg";
import client9Logo from "./images/Clients/31.jpg";
import client10Logo from "./images/Clients/32.jpg";
import client11Logo from "./images/Clients/33.jpg";
import client12Logo from "./images/Clients/34.jpg";
import client13Logo from "./images/Clients/35.jpg";
import client14Logo from "./images/Clients/Picture10.jpg";
import client15Logo from "./images/Clients/Picture1.jpg";
import client16Logo from "./images/Clients/Picture11.jpg"
import client17Logo from "./images/Clients/Picture12.jpg"
import client18Logo from "./images/Clients/Picture13.jpg"
import client19Logo from "./images/Clients/Picture14.jpg"
import client20Logo from "./images/Clients/Picture15.jpg"
import client21Logo from "./images/Clients/Picture16.jpg"
import client22Logo from "./images/Clients/Picture17.jpg"
import client23Logo from "./images/Clients/Picture18.jpg"
import client24Logo from "./images/Clients/Picture2.png"
import client25Logo from "./images/Clients/Picture3.png"
import client26Logo from "./images/Clients/Picture4.png"
import client27Logo from "./images/Clients/Picture6.png"
import client28Logo from "./images/Clients/Picture8.jpg"
import client29Logo from "./images/Clients/Picture9.jpg"
import client30Logo from "./images/Clients/cellpoint_logo.png"
import client31Logo from "./images/Clients/logo-123.jpg"
import client32Logo from "./images/Clients/logo_4.jpg"
import client33Logo from "./images/Clients/logo_5.jpg"
import client34Logo from "./images/Clients/logo_6.jpg"
import client35Logo from "./images/Clients/logo_7.jpg"
import client36Logo from "./images/Clients/logo_8.jpg"
import client37Logo from "./images/Clients/logo_9.jpg"
import client38Logo from "./images/Clients/logo_10.jpg"
import client39Logo from "./images/Clients/logo_11.jpg"
import client40Logo from "./images/Clients/logo_12.jpg"
import client41Logo from "./images/Clients/logo_13.jpg"
import client42Logo from "./images/Clients/logo_14.jpg"
import client43Logo from "./images/Clients/logo_15.jpg"
import client44Logo from "./images/Clients/logo_16.jpg"
import client46Logo from "./images/Clients/logo_18.jpg"
import client47Logo from "./images/Clients/logo_19.jpg"
import client48Logo from "./images/Clients/logo_20.jpg"
import banner from "./images/Slide Design Banner.jpg"

interface ImageData {
  id: number;
  src: string;
}

const images: ImageData[] = [
  { id: 1, src: slide1 },
  { id: 2, src: slide2 },
  { id: 3, src: slide3},
  { id: 4, src: slide4},
  { id: 5, src: slide5},
  { id: 6, src: slide6},
  { id: 7, src: slide7},
  { id: 8, src: slide8},
  { id: 9, src: slide9},
  { id: 10, src: slide10},
  { id: 11, src: slide11},
  { id: 12, src: slide12},
  { id: 13, src: slide13},
];

const clientLogos = [
  { name: "Client 1", logo: client1Logo },
  { name: "Client 2", logo: client2Logo },
  { name: "Client 3", logo: client3Logo },
  { name: "Client 4", logo: client4Logo },
  { name: "Client 5", logo: client5Logo },
  { name: "Client 6", logo: client6Logo },
  { name: "Client 7", logo: client7Logo },
  { name: "Client 8", logo: client8Logo },
  { name: "Client 9", logo: client9Logo },
  { name: "Client 10", logo: client10Logo },
  { name: "Client 11", logo: client11Logo },
  { name: "Client 12", logo: client12Logo },
  { name: "Client 13", logo: client13Logo },
  { name: "Client 14", logo: client14Logo },
  { name: "Client 15", logo: client15Logo },
  { name: "Client 16", logo: client16Logo },
  { name: "Client 18", logo: client18Logo },
  { name: "Client 19", logo: client19Logo },
  { name: "Client 20", logo: client20Logo },
  { name: "Client 21", logo: client21Logo },
  { name: "Client 22", logo: client22Logo },
  { name: "Client 23", logo: client23Logo },
  { name: "Client 24", logo: client24Logo },
  { name: "Client 25", logo: client25Logo },
  { name: "Client 26", logo: client26Logo },
  { name: "Client 27", logo: client27Logo },
  { name: "Client 28", logo: client28Logo },
  { name: "Client 29", logo: client29Logo },
  { name: "Client 30", logo: client30Logo },
  { name: "Client 31", logo: client31Logo },
  { name: "Client 32", logo: client32Logo },
  { name: "Client 33", logo: client33Logo },
  { name: "Client 34", logo: client34Logo },
  { name: "Client 35", logo: client35Logo },
  { name: "Client 36", logo: client36Logo },
  { name: "Client 37", logo: client37Logo },
  { name: "Client 38", logo: client38Logo },
  { name: "Client 39", logo: client39Logo },
  { name: "Client 40", logo: client40Logo },
  { name: "Client 41", logo: client41Logo },
  { name: "Client 42", logo: client42Logo },
  { name: "Client 43", logo: client43Logo },
  { name: "Client 44", logo: client44Logo },
  { name: "Client 46", logo: client46Logo },
  { name: "Client 47", logo: client47Logo },
  { name: "Client 48", logo: client48Logo },

  ];
  

const SlideDesign: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<ImageData | null>(null);

  const openModal = (image: ImageData) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="w-full">
      {/* Full-width Banner */}
      <div className="w-full relative overflow-hidden h-64 md:h-80 lg:h-96"> {/* Added fixed height */}
        <img 
          src={banner} 
          alt="Banner background" 
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 "></div>
        <div className="relative z-10 h-full flex items-center justify-center"> {/* Added flex container */}
          <div className="text-center text-white"> {/* Centered text container */}
            <h1 className="text-4xl md:text-5xl font-bold">Slide Design</h1>
            {/* <p className="text-xl md:text-2xl mt-4">Checkout some of our slide designs from over the years</p> */}
          </div>
        </div>
      </div>

      {/* Image Grid */}
      <div className="max-w-6xl mx-auto px-4 md:px-8 py-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {images.map((image) => (
            <div
              key={image.id}
              className="cursor-pointer overflow-hidden rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
              onClick={() => openModal(image)}
            >
              <img
                src={image.src}
                alt=""
                className="w-full h-auto object-cover"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg max-w-3xl w-full mx-4">
            <div className="flex justify-end mb-2">
              <button
                onClick={closeModal}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>
            <img
              src={selectedImage.src}
              alt=""
              className="w-full h-auto"
            />
          </div>
        </div>
      )}
      {/* Client Logos Section */}
      <div className="container mx-auto px-4 py-16 bg-gray-100">
        <h2 className="text-3xl font-bold mb-8 text-center">
          Some Of Our Happy Clients
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-4">
          {clientLogos.map((client, index) => (
            <div key={index} className="flex items-center justify-center p-8">
              <img
                src={client.logo}
                alt={client.name}
                className="max-w-full max-h-24 object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SlideDesign;